.order-heading {
	span {
		white-space: nowrap;
	}

	span:first-child {
		overflow: hidden;
		text-overflow: ellipsis;
		flex: 1;
	}
}

@hack: true; @import "/app/src/theme.less";