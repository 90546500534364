.customer-service-item {
	&.am-list-item {
		.service-tag {
			margin-right: 4px;
		}
	}

	&.am-list-item-error {
		.am-badge-text {
			background-color: @brand-error;
		}
	}
}

.customer-service-modal {
	width: 380px;
}

@hack: true; @import "/app/src/theme.less";