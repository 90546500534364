.login {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	// background: #145a35;
	background: radial-gradient(circle at center, #229457, #10492b);
	box-shadow: inset 0 50px 5px -5px rgba(0, 0, 0, 0.25);
	z-index: -1;

	.logo {
		width: 100%;
		height: auto;
		margin-bottom: 48px;
	}

	.am-card {
		box-shadow: 0 5px 8px -5px rgba(0, 0, 0, 0.5);
	}

	.am-card-header {
		.anticon {
			font-size: 40px;
			margin: @h-spacing-lg;
		}

		h2 {
			text-align: center;
			margin-right: 40px;
			flex-grow: 1;
		}
	}

	.am-card-body {
		display: flex;
		flex-direction: column;

		.am-list-item {
			.am-input-label {
				font-size: 20px;
				width: 32px;
			}
		}

		.login-button {
			margin: @v-spacing-lg 0 0 @h-spacing-lg;
			align-self: flex-end;
		}
	}
}

@hack: true; @import "/app/src/theme.less";