.welcome-title {
	font-weight: 300;
	padding: 0;
	margin: 0.6em 0 0.4em;
}

.welcome-subtitle {
	font-weight: 300;
	margin: 0 0 0.5em;
}

.other-others-title {
	font-weight: 300;
	margin: 1em 0 -0.5em 0;
}

.order-card {
	margin: 20px 0;
	box-shadow: 0 4px 6px -4px rgba(0, 0, 0, 0.3);

	.order-workplace-title {
		margin: 2px 8px;

		span {
			margin-right: 8px;
			vertical-align: middle;
		}

		&.primary {
			font-weight: 500;
		}
	}

	.am-card-header {
		.am-card-header-extra {
			max-width: fit-content;
		}
		.unit-tag {
			margin-right: 4px;
		}
	}

	.contact-name {
		margin-left: 4px;
	}

	transition: transform ease 0.2s;
	&:active {
		transform: scale(0.95);
	}
}

.am-result .result-large-icon {
	width: 48px;
	height: 48px;
}

.am-result.result-no-bg {
	background: none;
}

@hack: true; @import "/app/src/theme.less";