.sticky-header {
	background: @fill-body;
	padding: 8px 15px 5px 15px;
}

.order-unit-selector-wrapper {
	background: @search-bar-fill;
	padding-left: 10px;
	align-self: stretch;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-grow: 1;

	.order-unit-selector {
		// background: #ffffff;
		flex-grow: 1;
	}
}

.top-bar {
	height: 44px;
}

.unit-badge {
	color: #888;
	font-weight: 300;

	.anticon {
		color: #aaa;
	}

	.am-badge-text {
		margin-left: 5px;
	}

	&.unit-ordered,
	&.unit-todo,
	&.unit-completed {
		color: #000;
		font-weight: 500;

		.anticon {
			color: #000;
		}
	}

	&.unit-completed {
		.am-badge-text {
			background-color: transparent;

			.anticon {
				color: @brand-primary;
			}
		}
	}
}

@hack: true; @import "/app/src/theme.less";