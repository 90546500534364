.order-total-done,
.order-total-price {
	font-weight: 500;

	.am-list-line .am-list-extra {
		color: @color-text-base;
		flex-basis: 50%;
	}
}

@hack: true; @import "/app/src/theme.less";