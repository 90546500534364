.am-list-item .am-icon {
	vertical-align: middle;
}

.am-textarea-control textarea {
	font-family: inherit;
}

.am-modal {
	margin: 46px 0;
}
