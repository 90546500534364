.navigation {
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	right: 0;

	img.brand-logo {
		height: 14px;
		clip: rect(0, 0, 100%, 100%);
	}

	.am-search {
		background: none;
		width: 50vw;

		.am-search-input {
			background: #1e8a51;

			.am-search-synthetic-ph {
				.am-search-synthetic-ph-placeholder {
					color: white !important;
				}

				.am-search-synthetic-ph-icon {
					background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'38'%20height%3D'36'%20viewBox%3D'0%200%2038%2036'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M29.05%2025.23a15.81%2015.81%200%200%200%203.004-9.294c0-8.8-7.17-15.934-16.017-15.934C7.192.002.02%207.136.02%2015.936c0%208.802%207.172%2015.937%2016.017%2015.937%204.148%200%207.928-1.569%2010.772-4.143l8.873%208.232%202.296-2.45-8.927-8.282zM16.2%2028.933c-7.19%200-13.04-5.788-13.04-12.903%200-7.113%205.85-12.904%2013.04-12.904%207.19%200%2012.9%205.79%2012.9%2012.904%200%207.115-5.71%2012.903-12.9%2012.903z'%20fill%3D'%23fff'%20fill-rule%3D'evenodd'%2F%3E%3C%2Fsvg%3E");
				}
			}

			.am-search-clear {
				background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2028%2028'%3E%3Ccircle%20cx%3D'14'%20cy%3D'14'%20r%3D'14'%20fill%3D'%2345a170'%2F%3E%3Cline%20stroke%3D'%23ffffff'%20stroke-width%3D'2'%20stroke-miterlimit%3D'10'%20x1%3D'8'%20y1%3D'8'%20x2%3D'20'%20y2%3D'20'%2F%3E%3Cline%20fill%3D'none'%20stroke%3D'%23ffffff'%20stroke-width%3D'2'%20stroke-miterlimit%3D'10'%20x1%3D'20'%20y1%3D'8'%20x2%3D'8'%20y2%3D'20'%2F%3E%3C%2Fsvg%3E");
				margin: 6px;
			}

			input {
				color: white !important;
			}
		}

		.am-search-cancel {
			display: none;
		}
	}

	.nav-save-all {
		padding: 12px 0;
		display: inline-block;
		cursor: pointer;
		user-select: none;
	}
}

@hack: true; @import "/app/src/theme.less";