@service-grid-height: 508px;
@service-color-selected: #108ee9;

// .am-list > div:not(.service-total-price):last-of-type {
// 	border-bottom: none;
// }

.service-total-price {
	font-weight: 500;

	.am-list-line .am-list-extra {
		color: @color-text-base;
	}
}

.service-cash-payment {
	.am-list-line .am-list-extra {
		color: @color-text-base;
	}
}

.service-grid {
	height: @service-grid-height;

	.slider-list {
		height: @service-grid-height !important;
	}
}

.service-offer {
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: space-evenly;
	height: 100%;
	// background: #ff0000;

	.service-offer-sku {
		display: block;
		font-weight: 500;
		color: @color-text-base;
	}

	.service-offer-price {
		display: block;
	}
	.service-offer-interval {
		display: block;
	}

	&.primary .service-offer-sku {
		color: @brand-primary;
	}

	&.planned {
		background: @brand-success-light;
		color: @brand-primary;

		.service-offer-sku {
			color: @brand-primary;
		}
	}

	&.selected {
		background: @service-color-selected;
		color: @color-text-base-inverse;

		.service-offer-sku {
			color: @color-text-base-inverse;
		}
	}

	&.never {
		color: @brand-error;

		.service-offer-sku {
			color: @brand-error;
		}
	}
}

@hack: true; @import "/app/src/theme.less";