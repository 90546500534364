@interval-color-selected: #108ee9;

.customer-attribute {
	.am-textarea-label {
		white-space: normal;
		font-size: 1em;
		font-weight: 300;
		line-height: normal;
		padding: 1em 0;
		text-overflow: ellipsis;
		min-height: 0;
	}
}

.customer-service-interval {
	.am-list-line {
		.am-list-extra {
			flex-basis: 50%;
			color: @color-text-base;
		}
	}
}

.am-grid.customer-service-interval-grid {
	.am-grid-item {
		.am-grid-item-content {
			padding: 0;
			min-height: 64px;

			.service-interval {
				display: flex;
				flex-direction: column;
				align-content: center;
				justify-content: center;
				height: 100%;

				.service-interval-unit {
					line-height: 1;
					font-size: 0.7em;
				}

				&.selected {
					background: @interval-color-selected;
					color: @color-text-base-inverse;

					.service-interval-label {
						color: @color-text-base-inverse;
					}
				}
			}
		}
	}
}

.am-grid.customer-unit-grid {
	.am-grid-item {
		.am-grid-item-content {
			padding: 0;

			display: flex;
			flex-direction: column;
			align-content: stretch;
			justify-content: stretch;
			align-items: stretch;

			.customer-unit {
				padding: 20%;
				text-align: center;

				.customer-unit-label {
					margin-left: 5px;
				}

				&.selected {
					background: @interval-color-selected;
					color: @color-text-base-inverse;

					.service-interval-label {
						color: @color-text-base-inverse;
					}
				}
			}
		}
	}
}

.am-list-item.icon-input-label {
	.am-input-label {
		width: 24px;
	}
}

.address-modal {
	width: 320px;
}

@hack: true; @import "/app/src/theme.less";