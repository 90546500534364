.price-original {
	text-decoration: line-through;
	color: @color-text-disabled;
	margin-right: 8px;
}

.price-basic {
	color: @color-text-paragraph;
}

.service-item-remove {
	text-align: center;
	margin-right: 100px;

	.am-swipe-btn-text::before {
		content: "";
		display: block;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='10 10 27 27' width='1.2em' height='1.2em' fill='white'%3E%3Cpath fill-rule='evenodd' d='M24.008 21.852l8.97-8.968L31.092 11l-8.97 8.968L13.157 11l-1.884 1.884 8.968 8.968-9.24 9.24 1.884 1.885 9.24-9.24 9.24 9.24 1.885-1.884-9.24-9.24z'%3E%3C/path%3E%3C/svg%3E");
		width: 1.2em;
		height: 1.2em;
		margin: 4px;
	}
}

@hack: true; @import "/app/src/theme.less";