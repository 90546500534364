.customer-item {
	&.am-list-item {
		.am-list-brief {
			margin-top: 0;
		}

		.service-tag {
			margin-right: 4px;
		}

		.customer-note {
			// display: block;
			float: right;
			font-size: small;
			padding: 3px 4px 0;
			// margin-left: 16px;
			// clear: none;
		}
	}

	&.never .am-list-content {
		color: @brand-error;
	}
}

.customer-add-todo {
	text-align: center;

	.am-swipe-btn-text::before {
		content: "";
		display: block;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='64 64 910 910' width='1.2em' height='1.2em' fill='white' aria-hidden='true'%3E%3Cpath d='M567.1 512l318.5-319.3c5-5 1.5-13.7-5.6-13.7h-90.5c-2.1 0-4.2.8-5.6 2.3l-273.3 274-90.2-90.5c12.5-22.1 19.7-47.6 19.7-74.8 0-83.9-68.1-152-152-152s-152 68.1-152 152 68.1 152 152 152c27.7 0 53.6-7.4 75.9-20.3l90 90.3-90.1 90.3A151.04 151.04 0 00288 582c-83.9 0-152 68.1-152 152s68.1 152 152 152 152-68.1 152-152c0-27.2-7.2-52.7-19.7-74.8l90.2-90.5 273.3 274c1.5 1.5 3.5 2.3 5.6 2.3H880c7.1 0 10.7-8.6 5.6-13.7L567.1 512zM288 370c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80zm0 444c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z'%3E%3C/path%3E%3C/svg%3E");
		width: 1.2em;
		height: 1.2em;
		margin: 4px;
	}
}

.customer-remove-todo {
	text-align: center;

	.am-swipe-btn-text::before {
		content: "";
		display: block;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='10 10 27 27' width='1.2em' height='1.2em' fill='white'%3E%3Cpath fill-rule='evenodd' d='M24.008 21.852l8.97-8.968L31.092 11l-8.97 8.968L13.157 11l-1.884 1.884 8.968 8.968-9.24 9.24 1.884 1.885 9.24-9.24 9.24 9.24 1.885-1.884-9.24-9.24z'%3E%3C/path%3E%3C/svg%3E");
		width: 1.2em;
		height: 1.2em;
		margin: 4px;
	}
}

@hack: true; @import "/app/src/theme.less";