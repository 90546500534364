.switch-small {
	.checkbox {
		width: 28px;
		height: 18px;

		&::before {
			width: 24px;
			height: 14px;
			border-radius: 14px;
		}
		&::after {
			width: 14px;
			height: 14px;
			border-radius: 14px;
		}
	}

	input[type="checkbox"]:checked + .checkbox::after {
		transform: translateX(10px);
	}
}

.item-content-right {
	.am-popover-item-content {
		flex: 1 1 auto;
		text-align: right;
	}
}

.popover-item-switch {
	.am-popover-item-icon {
		width: 28px;
	}
}

.non-clickable {
	pointer-events: none;
}

@hack: true; @import "/app/src/theme.less";