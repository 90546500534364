.message-log {
	display: flex;
	flex-direction: column;
	align-items: stretch;

	height: 100%;
	background: #f5f5f9;
	overflow-y: auto;

	.log-input-line {
		.am-textarea-item {
			flex: 1;
		}
	}

	.log-message {
		margin: 1em;
		padding: 0.8em 1em 0.6em;

		background: #daf5e6;
		border-radius: 1em;
		box-shadow: inset 0 -1px 0 0px #cfe1d7;

		&.preparation-message {
			background: #dae6f5;
			padding: 0.6em 1em;
			box-shadow: inset 0 -1px 0 0px #cfd7e1;
		}

		.log-message-header {
			padding: 0;
			margin: 0;
			color: #154e30;
			font-size: 12px;
			font-weight: 400;
		}

		.log-message-content {
			padding: 0;
			margin: 0.5em 0 0.2em;
			font-size: 14px;
		}

		.log-message-meta {
			color: #888888;
			font-size: 11px;

			.log-message-updated {
				float: right;
				margin-left: 1em;
			}
		}
	}
}

@hack: true; @import "/app/src/theme.less";